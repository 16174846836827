import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PLATFORM, bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { ServiceRepairService } from 'services/service-repair-service';
import { ServiceService } from 'services/service-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { ServiceCounter } from './service-counter/service-counter';
import { ServiceTimeItemService } from 'services/service-time-item-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { UserService } from 'services/user-service';

@autoinject
export class ServiceDetailRepair {

  private net: Models.Net;
  private service: Models.Service;
  private netdimension: Models.NetDimension;
  private specialProductDimension: Models.SpecialProductDimension;
  private serviceRepairs: Array<Models.ServiceRepair>;
  private serviceid: number;
  private repairEditFormVisible = null;
  private tablesum: Array<any>;
  private originals: any = {};
  private originalObject;
  private locked;
  private nextTabIndex: number = null;

  @bindable private serviceId;
  @bindable private netId;
  @bindable private isDialog: boolean = false;

  private editTableData: boolean = false;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netDimensionService: NetDimensionService,
    private netService: NetService,
    private router: Router,
    private serviceRepairService: ServiceRepairService,
    private serviceService: ServiceService,
    private threadTypeService: ThreadTypeService,
    private toastService: ToastService,
    private utility: Utility,
    private specialProductDimensionService: SpecialProductDimensionService,
    private i18n: I18N,
    private deleteDialogService: DeleteDialogService,
    private userService: UserService
  ) { }

  private async activate(params) {
    this.getService(params.Id);
    this.getNet(params.NetId);
    this.getServiceRepairs(params.Id);    
    this.serviceid = params.Id;

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }

    this.eventAggregator.subscribe('serviceRepairListReset', (value) => {
      this.getServiceRepairs(this.serviceid);
      this.sumTable();
      return;
    });
  }

  private getService(id) {
    this.serviceService
    .get(id)
    .then((res) => {
      this.service = res;
      this.locked = res.Locked ? res.Locked : false;
      this.updateOriginalObject();
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
    .get(id + '?$expand=NetDimension($expand=NetType,DesignType),Nettings')
    .then((res) => {
      this.net = res;      
      this.getDimensions(res);      
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getDimensions(net: Models.Net) {
    if (net.NetDimensionId) {
      this.netDimensionService
        .get(net.NetDimensionId + '?$expand=DimensionClass')
        .then((res) => {
          this.netdimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    } else if (net.SpecialProductDimensionId) {
      this.specialProductDimensionService
        .get(net.SpecialProductDimensionId)
        .then((res) => {
          this.specialProductDimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private getServiceRepairs(id) {
    this.serviceRepairService
    .getAll('?$filter=ServiceId eq ' + id + '&$expand=ServiceRepairType&$orderby=ServiceRepairTypeId')
    .then((res) => {
      this.serviceRepairs = res;
      this.updateOriginalObject();
      this.sumTable();
    })
    .catch((err) => this.errorService.handleError(err));
  }

  updateStatusRepairedComplete() {
    this.serviceService.updateServiceStatus(4, this.service.Id)
      .then(res => {
        if (!this.service.RepairedDate) {
          this.service.RepairedDate = res.RepairedDate;
        }
        this.toastService.showSuccess('service.serviceStatusChanged');
        this.saveChanges();
      })
      .catch(err => this.errorService.handleError(err));
  }

  private saveChanges() {

    this.updateTableData();

    this.serviceService
    .put(this.service, this.serviceid)
    .then((res) => {
      this.toastService.showSuccess('service.repairSaved');
      this.eventAggregator.publish('serviceUpdated');
      this.updateOriginalObject();
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private sumTable() {

    // which headers should be included in the sum?
    const headers = ['Repairs1To3Msk', 'Repairs4To10Msk', 'RepairsOver10Msk', 'LooseRopes', 'BrokenLaces'];
    const sum = headers.map((header) => {
      const numberArray = this.serviceRepairs.map((serviceRepair) => {
        if (serviceRepair[header] === null) { return 0; }
        return parseInt(serviceRepair[header], 10);
      });      
      if (!numberArray || numberArray.length === 0) {
        return 0;
      }
      return numberArray.reduce((acc, val) => acc + val);
    });

    this.tablesum = sum;

  }

  public canDeactivate() {
    if (
      this.originalObject && 
      (
        !this.utility.areEqual(this.service, this.originalObject) || 
        !this.utility.areEqual(this.serviceRepairs, this.originals.serviceRepairs) ||
        this.editTableData
      )
    ) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'save') {
            // Save the service and let that function handle the rest of the logic
            this.saveChanges();
            return false;
          } else {
            this.cancelEditTableData();
            this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
            return true;
          }
        }
      });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  /*
    1	Hoppenett	Jumpnet
    2	Ved vannlinje	Waterline
    3	Øverst sider	Upper Side
    4	Midt på sider	Side Middle
    5	Nederst sider	Bottom Side
    6	Ved bunntau	Bottom Rope
    7	Ytterst bunn	Bottom Outer
    8	Innerst bunn	Bottom Inner
  */

  private updateOriginalObject() {
    if (this.service) {
      this.originalObject = JSON.parse(JSON.stringify(this.service));
    }
    if (this.serviceRepairs) {
      this.originals.serviceRepairs = JSON.parse(JSON.stringify(this.serviceRepairs));
    }
  }

  private openCounter() {
    this.dialogService.open({ viewModel: ServiceCounter, model: { Id: this.service.Id, NetId: this.net.Id }, lock: false, position: (mc, mo) => {} })
    .whenClosed((x) => {
      this.getServiceRepairs(this.serviceid);
    }).catch((err) => {
      this.errorService.handleError(err);
    });

  }

  private async updateTableData() {

    this.toastService.showSuccess('general.tableUpdating');

    try {
      for (const item of this.serviceRepairs) {

        // Check if changes has been made
        const originalItem = this.originals.serviceRepairs.find((x) => x.Id === item.Id);
        if (!this.utility.areEqual(originalItem, item)) {
          delete item.ServiceRepairType;
          if (!item.Id) {
            await this.serviceRepairService
            .post(item)
            .then((res) => {
              //
            })
            .catch((err) => this.errorService.handleError(err));
          } else {
            await this.serviceRepairService
            .put(item, item.Id)
            .then((res) => {
              //
            })
            .catch((err) => this.errorService.handleError(err));
          }
        }

      }

      this.getServiceRepairs(this.service.Id);
      this.sumTable();
      this.editTableData = false;
      this.toastService.showSuccess('general.tableUpdated');

    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private cancelEditTableData() {
    this.serviceRepairs = JSON.parse(JSON.stringify(this.originals.serviceRepairs));
    this.editTableData = false;
  }

  

}
