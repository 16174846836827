import { I18N } from 'aurelia-i18n';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CuttingStrapTypeService } from 'services/cutting-strap-type-service';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { PackagingMethodService } from 'services/packaging-method-service';
import { PackagingTypeService } from 'services/packaging-type-service';
import { PreparationRopeKnotPlacementService } from 'services/preparation-rope-knot-placement-service';
import { PreparationRopeKnotService } from 'services/preparation-rope-knot-service';
import { PreparationRopePlacementService } from 'services/preparation-rope-placement-service';
import { PreparationRopeTypeService } from 'services/preparation-rope-type-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ServiceConsumptionPreparationRopeService } from 'services/service-consumption-preparation-rope-service';
import { ServiceService } from 'services/service-service';
import { StrapTypeService } from 'services/strap-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { bindable } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, ValidationRules } from 'aurelia-validation';
import { InvoiceBadge } from 'components/badge/invoice-badge';
import { ServiceConsumptionPackagingService } from 'services/service-consumption-packaging-service';
import { ServiceConsumptionPackagingModel } from 'features/service-packaging-consumption-create-edit';
import { RopeTypeServiceService } from 'services';
import { RopeTypeService as RopeTypeServiceModel } from 'models';

@autoinject
export class ServiceDetailPreparation {
  private net: Models.Net;
  private netdimension: Models.NetDimension;
  private service: Models.Service;
  private packagingMethods: Array<Models.PackagingMethod>;
  private preparationRopeNewFormVisible = null;
  private preparationRopeEditFormVisible = null;
  private serviceConsumptionPreparationRopes: Array<Models.ServiceConsumptionPreparationRope>;
  private serviceConsumptionPackaging: Array<Models.SerivceConsumptionPackaging>;
  private newPreparationRope: Models.ServiceConsumptionPreparationRope;
  private currentRopeType: Models.RopeType;
  private currentRopeTypeService: RopeTypeServiceModel;
  private original: any;
  private originalObject;
  private locked;
  private nextTabIndex: number = null;
  protected headers = [];
  private invoiceConsumptionPackagingStatus: string;

  @bindable private serviceId;
  @bindable private netId;
  @bindable private isDialog: boolean = false;
  private invoiceConsumptionPreparationStatus: string;
  private validationController: ValidationController;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private validationControllerFactory: ValidationControllerFactory,
    private dialogService: DialogService,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility,
    private packagingMethodService: PackagingMethodService,
    private packagingTypeService: PackagingTypeService,
    private strapTypeService: StrapTypeService,
    private cuttingStrapTypeService: CuttingStrapTypeService,
    private serviceService: ServiceService,
    private netDimensionService: NetDimensionService,
    private preparationRopeTypeService: PreparationRopeTypeService,
    private ropeTypeService: RopeTypeService,
    private ropeTypeServiceService: RopeTypeServiceService,
    private preparationRopePlacementService: PreparationRopePlacementService,
    private preparationRopeKnotService: PreparationRopeKnotService,
    private preparationRopeKnotPlacementService: PreparationRopeKnotPlacementService,
    private i18n: I18N,
    private serviceConsumptionPreparationRopeService: ServiceConsumptionPreparationRopeService,
    private serviceConsumptionPackagingService: ServiceConsumptionPackagingService
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.createHeaders();
  }

  private attached(p) {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }
  }

  private activate(params) {
    if (!this.serviceId) this.serviceId = params.Id;
    if (!this.netId) this.netId = params.NetId;
    this.getService(params.Id);
    this.getNet(params.NetId);
    this.getPackagingMethods();
    this.getRopeTypes();
    this.getRopeTypesService();
    this.getServiceConsumptionPreparationRopes(params.Id);
    this.getServiceConsumptionPackaging(params.Id);

    this.eventAggregator.subscribe('serviceConsumptionPreparationRopesListReset', (value) => {
      this.getServiceConsumptionPreparationRopes(this.service.Id);
    });

    this.eventAggregator.subscribe('serviceConsumptionPreparationRopesListCancel', (value) => {
      this.preparationRopeEditFormVisible = null;
      this.serviceConsumptionPreparationRopes = JSON.parse(JSON.stringify(this.original));
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  protected isCreating = false;
  setIsCreating(isCreating: boolean) {
    this.editingConsumptionPackaging = undefined;
    this.isCreating = isCreating;
  }

  editingConsumptionPackaging: number | undefined = undefined;
  setEditingConsumptionPackaging(consumptionPackagingId: number) {
    this.isCreating = false;
    this.editingConsumptionPackaging = consumptionPackagingId;
  }

  private updateServiceStatus(status) {
    this.serviceService
      .updateServiceStatus(status, this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('service.serviceStatusChanged');
        this.saveService();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private saveService() {
    this.serviceService
      .put(this.service, this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('service.preparationSaved');
        this.eventAggregator.publish('serviceUpdated');
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getService(id) {
    this.serviceService
      .get(id)
      .then((res) => {
        this.service = null;
        this.service = res;
        this.locked = res.Locked ? res.Locked : false;
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async getServiceConsumptionPackaging(serviceId: number) {
    const result = await this.serviceConsumptionPackagingService.getAll(
      '?$expand=PackagingProduct($expand=Product)&$filter=ServiceId eq ' + (serviceId || this.serviceId)
    );
    this.serviceConsumptionPackaging = result;
    const invoiceConsumptionPackagingStatusList = this.serviceConsumptionPackaging.map((x) =>
      x.ServiceInvoiceId ? true : false
    );
    this.invoiceConsumptionPackagingStatus = InvoiceBadge.GetInvoiceType(invoiceConsumptionPackagingStatusList);
  }

  private getServiceConsumptionPreparationRopes(id) {
    this.serviceConsumptionPreparationRopeService
      .getAll(
        '?$filter=ServiceId eq ' +
          id +
          '&$expand=RopeType($expand=NavisionProduct),RopeTypeService($expand=Product),PreparationRopeType,RopeDimension,PreparationRopePlacement,PreparationRopeKnot1,PreparationRopeKnot2,PreparationRopeKnotPlacement1,PreparationRopeKnotPlacement2'
      )
      .then((res) => {
        this.serviceConsumptionPreparationRopes = res;
        this.original = JSON.parse(JSON.stringify(res));
        const invoiceConsumptionPreparationStatusList = this.serviceConsumptionPreparationRopes.map((x) =>
          x.ServiceInvoiceId ? true : false
        );
        this.invoiceConsumptionPreparationStatus = InvoiceBadge.GetInvoiceType(invoiceConsumptionPreparationStatusList);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension($expand=NetType,DesignType),Nettings')
      .then((res) => {
        this.net = res;
        if (res.NetDimensionId) {
          this.getDimensions(res.NetDimensionId);
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getDimensions(NetDimensionId) {
    this.netDimensionService
      .get(NetDimensionId + '?$expand=DimensionClass')
      .then((res) => {
        this.netdimension = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getPackagingMethods() {
    this.packagingMethodService
      .getAll()
      .then((res) => {
        this.packagingMethods = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected async onSave(data: ServiceConsumptionPackagingModel) {
    if (data.Id) {
      await this.doUpdatePackagingConsumption(data);
    } else {
      await this.doCreatePackagingConsumption(data);
    }
  }

  protected async doCreatePackagingConsumption(data: ServiceConsumptionPackagingModel) {
    await this.serviceConsumptionPackagingService.post(data as Models.SerivceConsumptionPackaging);
    await this.onSaved('create');
  }

  protected async doUpdatePackagingConsumption(data: ServiceConsumptionPackagingModel) {
    await this.serviceConsumptionPackagingService.put(data as Models.SerivceConsumptionPackaging, data.Id);
    await this.onSaved('update');
  }

  protected async onSaved(type: 'create' | 'update') {
    if (type === 'create') {
      this.setIsCreating(false);
      this.toastService.showSuccess(this.i18n.tr('general.created'));
      await this.getServiceConsumptionPackaging(this.serviceId);
    } else {
      this.setEditingConsumptionPackaging(undefined);
      this.toastService.showSuccess(this.i18n.tr('general.updated'));
      await this.getServiceConsumptionPackaging(this.serviceId);
    }
  }

  async onDelete(id: number) {
    try {
      await this.serviceConsumptionPackagingService.delete(id);
      this.toastService.showSuccess(this.i18n.tr('general.entryDeleted'));
      await this.getServiceConsumptionPackaging(this.serviceId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
  private async getRopeTypesService() {
    const res = await this.ropeTypeServiceService.getAll('?$expand=Product');

    return res
      .filter((x) => x.ProductId)
      .map((x_1) => {        
        return { Id: x_1.Id, Name: x_1.Name + " (" + x_1.Product.ArticleNo + ")"};
      });
  }

  private async getRopeTypes() {
    const ropeTypes = await this.ropeTypeService.getAll();

    ropeTypes.forEach((ropeType) => {
        if(ropeType.NavisionProduct){
          ropeType.Name = ropeType.Name + " (" + ropeType.NavisionProduct.ArticleNo + ")";
          ropeType.NameEn = ropeType.NameEn + " (" + ropeType.NavisionProduct.ArticleNo + ")";
          ropeType.NameEs = ropeType.NameEs + " (" + ropeType.NavisionProduct.ArticleNo + ")";
        }
      })

    return ropeTypes.filter((x) => x.NavisionProductId || x.DeltaProductId);
  }

  protected ropeTypeChanged(event) {
    if (!event.detail.value || event.detail.value === this.newPreparationRope.RopeTypeId) {
      return;
    }
    this.newPreparationRope.RopeTypeId = event.detail.value;
    this.ropeTypeService
      .get(this.newPreparationRope.RopeTypeId)
      .then((res) => {
        this.currentRopeType = res;
        this.newPreparationRope.RopeDimensionId = this.currentRopeType.RopeDimensionId;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected ropeTypeServiceChanged(event) {
    if (!event.detail.value || event.detail.value === this.newPreparationRope?.RopeTypeServiceId) {
      return;
    }
    this.newPreparationRope.RopeTypeServiceId = event.detail.value;
    this.ropeTypeServiceService
      .get(this.newPreparationRope.RopeTypeServiceId)
      .then((res) => {
        this.currentRopeTypeService = res;
        this.newPreparationRope.RopeDimensionId = this.currentRopeTypeService.RopeDimensionId;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private addPreparationRope() {
    this.preparationRopeNewFormVisible = true;
    this.newPreparationRope = new Models.ServiceConsumptionPreparationRope();
    if (this.service.IsInvoicingCompatible) {
      ValidationRules.ensure('PreparationRopeTypeId')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .ensure('RopeTypeServiceId')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .ensure('Amount')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .min(0)
        .withMessage(this.i18n.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
        .on(this.newPreparationRope);
    } else {
      ValidationRules.ensure('PreparationRopeTypeId')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .on(this.newPreparationRope);
    }
  }

  private async createPreparationRope() {
    const validationResult = await this.validationController.validate({ object: this.newPreparationRope });

    if (!validationResult.valid) {
      return;
    }

    this.newPreparationRope.ServiceId = this.service.Id;
    this.serviceConsumptionPreparationRopeService
      .post(this.newPreparationRope)
      .then((res) => {
        this.newPreparationRope = null;
        this.preparationRopeNewFormVisible = null;
        this.eventAggregator.publish('serviceConsumptionPreparationRopesListReset', 0);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deletePreparationRope(id) {
    this.serviceConsumptionPreparationRopeService
      .delete(id)
      .then((res) => {
        this.eventAggregator.publish('serviceConsumptionPreparationRopesListReset', 0);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  editPreparationRope(preparationrope) {
    this.preparationRopeEditFormVisible = preparationrope.Id;
    if (this.service.IsInvoicingCompatible) {
      ValidationRules.ensure('PreparationRopeTypeId')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .ensure('RopeTypeServiceId')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .ensure('Amount')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .min(0)
        .withMessage(this.i18n.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
        .on(preparationrope);
    } else {
      ValidationRules.ensure('PreparationRopeTypeId')
        .required()
        .withMessage(this.i18n.tr('general.requiredField'))
        .on(preparationrope);
    }
  }

  private async updatePreparationRope(preparationrope) {
    const validationResult = await this.validationController.validate({ object: preparationrope });

    if (!validationResult.valid) {
      return;
    }

    preparationrope.RopeType = null;
    preparationrope.PreparationRopeType = null;
    preparationrope.RopeDimension = null;
    preparationrope.PreparationRopePlacement = null;
    preparationrope.PreparationRopeKnot1 = null;
    preparationrope.PreparationRopeKnot2 = null;
    preparationrope.PreparationRopeKnotPlacement1 = null;
    preparationrope.PreparationRopeKnotPlacement2 = null;

    this.serviceConsumptionPreparationRopeService
      .put(preparationrope, preparationrope.Id)
      .then((res) => {
        this.preparationRopeEditFormVisible = null;
        this.eventAggregator.publish('serviceConsumptionPreparationRopesListReset', 0);
        this.original = JSON.parse(JSON.stringify(this.serviceConsumptionPreparationRopes));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async copyPackagingFromLastDeliveryToSite() {
    if (this.service.Locked) {
      return;
    }

    if (this.service.DeliveredToSiteId) {
      this.serviceService
        .getAll(
          `?$top=1&$filter=Id ne ${this.service.Id} and ApprovedService ne null and DeliveredToSiteId eq ${this.service.DeliveredToSiteId}&$orderby=DeliveredDate desc`
        )
        .then(async (lastServices) => {
          if (lastServices.length === 0) {
            this.toastService.showError('service.nopreviousdeliveriestositesetcannotcopypackaging');
          } else {
            const lastService = lastServices[0];

            this.service.StrapTypeId = lastService.StrapTypeId;
            this.service.CuttingStrapTypeId = lastService.CuttingStrapTypeId;
            this.service.PackagingTypeId = lastService.PackagingTypeId;
            this.service.PackagingMethodId = lastService.PackagingMethodId;
            this.service.PreparationComment = lastService.PreparationComment;

            // add the preparationropes from the last service to this service
            let ropesLastService = await this.serviceConsumptionPreparationRopeService.getAll(
              `?$filter=ServiceId eq ${lastService.Id}`
            );

            if (ropesLastService && ropesLastService.length == 0) {
              // no ropes on last service, no need to copy/ask user anything
              this.toastService.showSuccess('service.packagingDataCopied');
              return;
            }

            let doCopyPreparationRopes = async () => {
              for (let existingRope of this.serviceConsumptionPreparationRopes) {
                await this.serviceConsumptionPreparationRopeService.delete(existingRope.Id);
              }

              for (let rope of ropesLastService) {
                let copiedRope = JSON.parse(JSON.stringify(rope));
                copiedRope.ServiceId = this.service.Id;
                copiedRope.Id = 0;

                await this.serviceConsumptionPreparationRopeService.post(copiedRope);

                this.serviceConsumptionPreparationRopes.push(copiedRope);
              }

              this.eventAggregator.publish('serviceConsumptionPreparationRopesListReset', 0);
              this.original = JSON.parse(JSON.stringify(this.serviceConsumptionPreparationRopes));
              this.toastService.showSuccess('service.packagingDataCopied');
            };

            if (this.serviceConsumptionPreparationRopes && this.serviceConsumptionPreparationRopes.length > 0) {
              // ropes already added to service - add anyway? check with user
              this.dialogService
                .open({
                  viewModel: Prompt,
                  model: {
                    header: 'service.copyPreparationRopes',
                    message: 'service.copyPreparationRopeMessage',
                    actions: {
                      continue: { enabled: true, t: 'service.copyPreparationRopes' },
                      cancel: { enabled: true, t: 'dialog.cancel' },
                    },
                  },
                })
                .whenClosed(async (res) => {
                  if (!res.wasCancelled) {
                    await doCopyPreparationRopes();
                  }
                });
            } else {
              await doCopyPreparationRopes();
            }
          }
        })
        .catch((err) => this.errorService.handleError(err));
    } else {
      this.toastService.showError('service.nodeliveredtositesetcannotcopypackaging');
    }
  }

  protected async getPackagingType() {
    const packagingTypes = await this.packagingTypeService.getAll('?$expand=NavisionProduct');

    packagingTypes.forEach((packaginType) => {
      if(packaginType.NavisionProduct){
        packaginType.Name = packaginType.Name + " (" + packaginType.NavisionProduct.ArticleNo + ")";
        packaginType.NameEn = packaginType.NameEn + " (" + packaginType.NavisionProduct.ArticleNo + ")";
        packaginType.NameEs = packaginType.NameEs + " (" + packaginType.NavisionProduct.ArticleNo + ")";
      }
    })
    
    return packagingTypes
  }

  private setPackagingFileId(event: any) {
    this.service.PackagingFileId = event.detail.id;
  }

  private removePackagingFileId(event: any) {
    this.service.PackagingFileId = null;
  }

  public canDeactivate() {
    if (
      this.preparationRopeNewFormVisible ||
      this.preparationRopeEditFormVisible ||
      !this.utility.areEqual(this.service, this.originalObject)
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private updateOriginalObject() {
    this.originalObject = JSON.parse(JSON.stringify(this.service));
  }

  createHeaders() {
    this.headers = [
      { title: this.i18n.tr('service.packagingproducts') },
      { title: this.i18n.tr('general.articleno') },
      { title: this.i18n.tr('service.Consumption') },
      { title: this.i18n.tr('general.actions') },
    ];
  }
}
